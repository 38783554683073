export const sections = [
    {
        title: "Home",
        url: "/",
        exact: true,
    },
    {
        title: "Podcast",
        url: "/podcast",
    },
    {
        title: "Opinion",
        url: "/opinion",
    }
];